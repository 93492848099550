export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Date',
    accessor: 'insert_date',
  },
  {
    Header: 'Notification Name',
    accessor: 'notification_name',
  },
  {
    Header: 'Visits',
    accessor: 'nb_visits',
  },
  {
    Header: 'Description',
    accessor: 'notification_description',
  },
];
export const unique_notification = [
  {
    Header: 'Notification Name',
    accessor: 'notification_name',
  },
  {
    Header: 'Notification Sent Date',
    accessor: 'date_value',
  },
  {
    Header: 'Total Notifications Sent',
    accessor: 'notification_count',
  },
  {
    Header: 'Total Notifications Clicked',
    accessor: 'number_of_unique_visitors',
  },
];
