export const usageTrackingHeader = [
  {
    Header: 'Page Title',
    accessor: 'page_title',
  },
  {
    Header: 'Visits',
    accessor: 'count_visits',
  },
  {
    Header: 'Unique Visits',
    accessor: 'uniqueusers',
  },
  {
    Header: 'Total Time Spent',
    accessor: 'totaltimespent',
  },
];
