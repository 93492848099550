export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'Login Date',
    accessor: 'date_logged_in',
  },
  {
    Header: 'Page Visited (Path)',
    accessor: 'pages_visited',
  },
];
