import React, { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { unique_outlinks } from './outlinks';
import { apiResponse, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { MetricProps } from '../usage-metric-interface';

const OutlinksChart = (props: MetricProps) => {
  const count_columns = useMemo(() => unique_outlinks, []);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [outlinkCountData, setOutlinkCountData] = useState([]);
  const [outlinkCountOriginalData, setOutlinkCountOriginalData] = useState([]);

  const [filterTitleVal, setFilterTitleVal] = useState('');
  const [isTitleFilterDisabled, setTitleFilterDisabled] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setFilterTitleVal('');
      setOutlinkCountData([]);
      setOutlinkCountOriginalData([]);
      getOutlinkCountData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (outlinkCountOriginalData.length === 0) return;
    setFilterTitleVal('');
    setTitleFilterDisabled(false);
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, outlinkCountOriginalData]);

  const filterGroupValue = (dataArray: []) => {
    const countData = filterMembers(outlinkCountOriginalData, props.userGroupValue, props.includeCoreMember);
    if (countData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setTitleFilterDisabled(true);
    } else {
      setTitleFilterDisabled(false);
    }
    setOutlinkCountData(countData);
  };

  const getOutlinkCountData = async () => {
    setLoading(true);
    const payload = {
      metric_id: '15',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('OutlinksChart: Data not available!!');
          } else {
            setOutlinkCountOriginalData(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric);
          }
        }
      } else {
        console.log(res);
        toast.error('OutlinksChart Count: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('OutlinksChart: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRowsbyTitle = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(outlinkCountOriginalData, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: { pagetitle: string }) => obj.pagetitle.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      setOutlinkCountData(dataArray);
    } else {
      setOutlinkCountData(filterArr);
    }
    setFilterTitleVal(evt.target.value);
  };

  return (
    <>
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterTitleVal}
            onChange={evt => {
              filterRowsbyTitle(evt);
            }}
            placeholder={'Filter Page Title'}
            disabled={isTitleFilterDisabled}
          ></input>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {outlinkCountData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table unique-outlinks-table">
              <thead>
                <tr>
                  {count_columns.map((data, indx: number) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {outlinkCountData.map((data, indx: number) => {
                  return (
                    <tr key={indx}>
                      {unique_outlinks.map((colData, colIndx: number) => {
                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};

export default OutlinksChart;
