import React, { ReactNode } from 'react';
import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { unique_notification } from './usageTrackingTable';
import { apiResponse } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { MetricProps } from '../usage-metric-interface';
import { format } from 'date-fns';

const NotificationsCount = (props: MetricProps) => {
  const count_columns = useMemo(() => unique_notification, []);
  const [isLoading, setLoading] = useState(true);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [notificationCountData, setNotificationCountData] = useState([]);
  const [notificationCountDataOriginal, setNotificationCountDataOriginal] = useState([]);
  const [filterVal, setFilterVal] = useState('');
  const [isFilterDisabled, setFilterDisabled] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setNotificationCountData([]);
      getnotificationCountData();
    }
  }, [props.clearData]);

  const getnotificationCountData = async () => {
    setLoading(true);
    const payload = {
      metric_id: '17',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            setTableMsg('No Data Available');
            // toast.error('Notifications Count: Data not available!!');
          } else {
            const countData = res.data.data.user_metric;
            setNotificationCountDataOriginal(countData);
            setNotificationCountData(countData);
          }
        }
      } else {
        console.log(res);
        toast.error('Notifications: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Notifications: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let filterArr = notificationCountDataOriginal.filter(
      (obj: { notification_name: string }) => obj.notification_name.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1,
    );
    if (evt.target.value === '') {
      setNotificationCountData(notificationCountDataOriginal);
    } else {
      setNotificationCountData(filterArr);
    }
    setFilterVal(evt.target.value);
  };

  return (
    <div className="kpi-metric">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter Notification Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {notificationCountData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table unique-notifications-table">
              <thead>
                <tr>
                  {count_columns.map((data, indx: number) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {notificationCountData.map((data, indx: number) => {
                  return (
                    <tr key={indx}>
                      {unique_notification.map((colData, colIndx: number) => {
                        return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default NotificationsCount;
