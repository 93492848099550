import { useState, useEffect, useMemo } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, profileMatamo, workDayRedirect, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import ExternalLinkLogo from '../../../assets/icons/external-link';
import Profile from '../../../assets/images/Profile.png';
import { RowSpanType, UserInPauseProps } from '../usage-metric-interface';

const UserInPause = (props: UserInPauseProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);

  useEffect(() => {
    setFilterVal('');
    setUsageTrackingData([]);
    setUsageTrackingOriginal([]);
    getUsageTrackingData();
  }, []);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterDisabled(false);
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }

    const filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      setTableMsg(`No Data Available`);
      setFilterDisabled(false);
    }
    setUsageTrackingData(filteredData);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '10',
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Paused Activity By User: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: any, b: any) => {
              if (a.visitcount > b.visitcount) {
                return -1;
              } else if (a.visitcount < b.visitcount) {
                return 1;
              } else {
                return 0;
              }
            });
            filterGroupValue(res.data.data.user_metric); //Filter By User Group & SetUsageTrackingData
            //setUsageTrackingData(res.data.data.user_metric);
            setUsageTrackingOriginal(res.data.data.user_metric);
          }
        }
      } else {
        console.log(res);
        toast.error('Paused Activity By User: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Paused Activity By User: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: any) => obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      setUsageTrackingData(usageTrackingOriginal);
    } else {
      setUsageTrackingData(filterArr);
    }
    setFilterVal(evt.target.value);
  };

  return (
    <div className="usage-metric paused-user-activity">
      <div className="metric-header">
        <div>
          <label className="form-label">Since Past 30 Days</label>
        </div>
        <div className="non-user-group-note">
          <p>* Users who have not visited Sensing in the last 30 days</p>
        </div>

        <div className="filters">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter User Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table paused-user-activity-table">
              <thead>
                <tr>
                  {columns.map((data: any, indx: any) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {usageTrackingData.map((data: any, indx: any) => {
                  return (
                    <tr key={indx}>
                      {columns.map((colData: any, colIndx: any) => {
                        if (colData.accessor === 'userid') {
                          return (
                            <td className="faux-link" key={indx + colIndx} onClick={() => workDayRedirect(data.profiledeeplink)}>
                              <span className="user-id" data-testid={data[colData.accessor]}>
                                {data[colData.accessor]}
                              </span>
                              <span className="external-link">
                                <ExternalLinkLogo />
                              </span>
                            </td>
                          );
                        } else if (colData.accessor === 'fullname') {
                          return (
                            <td className="faux-link" key={indx + colIndx} onClick={() => profileMatamo(data['visitorid'], '', '')}>
                              <span className="user-name">{data[colData.accessor]}</span>
                              <span className="external-link">
                                <img src={Profile} alt="profile-link" className="profile" />
                              </span>
                            </td>
                          );
                        } else {
                          return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default UserInPause;
