export const outlinks = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  // {
  //   Header: "Position",
  //   accessor: "position",
  // },
  // {
  //   Header: "Function",
  //   accessor: "function",
  // },
  {
    Header: 'Page Title',
    accessor: 'pagetitle',
  },
  {
    Header: 'Date',
    accessor: 'insert_date',
  },
  {
    Header: 'URL',
    accessor: 'visited_url',
  },
];
export const unique_outlinks = [
  {
    Header: 'Page Title',
    accessor: 'pagetitle',
  },
  {
    Header: 'Outlinks',
    accessor: 'visited_url',
  },
  {
    Header: 'Count',
    accessor: 'count_of_visits',
  },
];
