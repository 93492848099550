import { Modal } from '@gitlab-rtsensing/component-library';
import SearchByUser from '../search-by-user';
import AmgenNetworks from '../../../assets/icons/amgen-networks';
import Logo from '../../../assets/images/Amgen-Sensing.png';

interface SearchModalProps {
  opened: boolean;
  searchModalClosed: () => void;
  userID?: string;
}

export default function SearchModal(props: SearchModalProps): JSX.Element {
  const { opened, searchModalClosed, userID } = props;
  return (
    <Modal className="search-user-access-modal" opened={opened} onClose={searchModalClosed} disableBackdropDismiss={true}>
      <Modal.Header contentClassName="d-flex align-items-center" className="search-user-modal-header">
        <AmgenNetworks fill="#000" />
        <img src={Logo} alt="amgen logo"></img>
        <Modal.Title className="search-user-modal-title" title={'Search By User'} />
      </Modal.Header>
      <SearchByUser searchModalClosed={searchModalClosed} userID={userID} />
    </Modal>
  );
}
