import { useState, useEffect, useMemo, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, profileMatamo, workDayRedirect, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import ExternalLinkLogo from '../../../assets/icons/external-link';
import Profile from '../../../assets/images/Profile.png';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import SortDownIcon from '../../../assets/icons/sort-down-icon';
import SortUpIcon from '../../../assets/icons/sort-up-icon';

const UserLoginHistory = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const [isSorted, setIsSorted] = useState(false);
  const [direction, setDirection] = useState('asc');
  const [totalvisits, setTotalvisits] = useState(0);

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
      setFilterDisabled(true);
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      //All Other case
      setFilterDisabled(false);
    }
    setUsageTrackingData(filteredData);
    let totalCount = 0;
    filteredData.map((count: any) => {
      totalCount += count.visitcount;
      return totalCount;
    });
    getTotalVisit(filteredData);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '1',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Login History: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: { visitcount: number }, b: { visitcount: number }) => {
              if (a.visitcount > b.visitcount) {
                return -1;
              } else if (a.visitcount < b.visitcount) {
                return 1;
              } else {
                return 0;
              }
            });
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            setUsageTrackingOriginal(res.data.data.user_metric);
            let totalCount = 0;
            res.data.data.user_metric.map((count: any) => {
              totalCount += count.visitcount;
              return totalCount;
            });
            //getTotalVisit(totalCount);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Login History: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Login History: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: { fullname: string }) => obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      setUsageTrackingData(dataArray);
      getTotalVisit(dataArray);
    } else {
      setUsageTrackingData(filterArr);
      getTotalVisit(filterArr);
    }
    setFilterVal(evt.target.value);
  };

  const getTotalVisit = (data: { visitcount: number }[]) => {
    let totalCount = 0;
    data.map((count: { visitcount: number }) => {
      totalCount += count.visitcount;
      return totalCount;
    });
    setTotalvisits(totalCount);
  };

  const sortAscending = () => {
    usageTrackingData.sort((a, b) => {
      if (a.visitcount > b.visitcount) {
        return -1;
      } else if (a.visitcount < b.visitcount) {
        return 1;
      } else {
        return 0;
      }
    });
    setDirection('asc');
    setIsSorted(true);
  };

  const sortDescending = () => {
    usageTrackingData.sort((a, b) => {
      if (a.visitcount < b.visitcount) {
        return -1;
      } else if (a.visitcount > b.visitcount) {
        return 1;
      } else {
        return 0;
      }
    });
    setDirection('desc');
    setIsSorted(true);
  };
  return (
    <div className="usage-metric login-history">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters ">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter User Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>

      <div className="metric-header no-left-field color-theme">{usageTrackingData.length > 0 ? <label> Total Visits : {totalvisits} </label> : null}</div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table ant-table-content user-login-history">
              <thead className="ant-table-thead">
                <tr>
                  {columns.map((data, indx: number) => {
                    if (data.Header === 'Visits') {
                      return (
                        <th key={indx} className="ant-table-cell">
                          <div className="usage-metric-visit">
                            {data.Header}
                            <span className="sortIcon">
                              <span className="upArrow" onClick={sortAscending}>
                                <SortUpIcon fill={isSorted && direction === 'asc' ? '#0063c3' : '#2F2F2F'} />
                              </span>
                              <span onClick={sortDescending}>
                                <SortDownIcon fill={isSorted && direction === 'desc' ? '#0063c3' : '#2F2F2F'} />
                              </span>
                            </span>
                          </div>
                        </th>
                      );
                    } else {
                      return (
                        <th key={indx} className="ant-table-cell">
                          {data.Header}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {usageTrackingData.map((data, indx: number) => {
                  return (
                    <tr key={indx}>
                      {columns.map((colData, colIndx: number) => {
                        if (colData.accessor === 'userid') {
                          return (
                            <td className="faux-link" key={indx + colIndx} onClick={() => workDayRedirect(data.profiledeeplink)}>
                              <span className="user-id">{data[colData.accessor]}</span>
                              <span className="external-link">
                                <ExternalLinkLogo />
                              </span>
                            </td>
                          );
                        } else if (colData.accessor === 'fullname') {
                          return (
                            <td
                              className="faux-link"
                              key={indx + colIndx}
                              onClick={() => profileMatamo(data['visitorid'], props.date.matamoType, props.date.matamoDate)}
                            >
                              <span className="user-name">{data[colData.accessor]}</span>
                              <span className="external-link">
                                <img src={Profile} alt="profile-link" className="profile" />
                              </span>
                            </td>
                          );
                        } else {
                          return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default UserLoginHistory;
