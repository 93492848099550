import React, { ReactNode, useMemo } from 'react';
import { useState, useEffect, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, rowSpanning, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { timeToHrMinSecond } from '../utility';
import { AppContext } from '../../../contexts/app-context';
import { RowSpanType, MetricProps } from '../usage-metric-interface';
import useRenderSpanning from '../../../hooks/use-render-spanning';

function getPathFromUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.pathname;
}

const UnauthorizedTracking = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const tableData = useRenderSpanning(usageTrackingData, columns, props.date, 'unauthorizedtracking', false);

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
      let filteredData = dataArray.filter((obj: any) => obj.userid === userFilter.userId);

      setFilterDisabled(true);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
        setUsageTrackingData(filteredData);
        return;
      }
      alignFilterData(filteredData);
    } else {
      filterGroupValue(usageTrackingOriginal);
      setFilterDisabled(false);
      setTableMsg('No Data Available');
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');

    setFilterDisabled(false);
    filterGroupValue([]);
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }

    const filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else if (userFilter.filter) {
      setFilterDisabled(true);
    } else {
      setFilterDisabled(false);
    }

    setUsageTrackingData(rowSpanning(filteredData));
  };

  const alignFilterData = (dataArray: any) => {
    let filteredData = dataArray;
    if (filteredData.length === 0) {
      setTableMsg(`No Data Available`);
    } else if (userFilter.filter) {
      setFilterDisabled(true);
    } else {
      setFilterDisabled(false);
    }

    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '6',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Unauthorized Tracking: Data not available!!');
          } else {
            res.data.data.user_metric = res.data.data.user_metric.map((obj: any) => {
              return Object.assign(obj, {
                timespent: timeToHrMinSecond(parseInt(obj.timespent)),
              });
            });
            res.data.data.user_metric = res.data.data.user_metric.map((metric: any) => {
              metric.from_url = metric.from_url === '' ? '' : getPathFromUrl(metric.from_url);
              return metric;
            });

            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric); //Filter By User Group & SetUsageTrackingData
            //setUsageTrackingData(res.data.data.user_metric);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        props.setClearData(false);
        toast.error('Unauthorized Tracking: Failed to load!!');
      }
    } catch (error) {
      console.log(error, columns);
      props.setClearData(false);
      toast.error('Unauthorized Tracking: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: any) => obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      alignFilterData(dataArray);
    } else {
      alignFilterData(filterArr);
    }
    setFilterVal(evt.target.value);
  };

  return (
    <div className="usage-metric unauthorized-tracking">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter User Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table unauth-table">
              <thead>
                <tr>
                  {columns.map((data: any, indx: any) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default UnauthorizedTracking;
