export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Function',
    accessor: 'function',
  },
  {
    Header: 'Visits',
    accessor: 'visitcount',
  },
  {
    Header: 'First Visit',
    accessor: 'first_visit_date',
  },
  {
    Header: 'Last Visit',
    accessor: 'last_visit_date',
  },
];
