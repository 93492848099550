import { ReactNode } from 'react';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, profileMatamo, rowSpanning, workDayRedirect, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import useRenderSpanning from '../../../hooks/use-render-spanning';

function getPathFromUrl(url: string): string {
  const urlObject = new URL(url);
  return urlObject.pathname;
}

const VisitorLog = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const tableData = useRenderSpanning(usageTrackingData, columns, props.date, 'newvisitorlog', false);

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
      setFilterDisabled(true);
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      //All Other case
      setFilterDisabled(false);
    }
    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '4',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Visitor Log: Data not available!!');
          } else {
            //Below is for time formating
            res.data.data.user_metric = res.data.data.user_metric.map((metric: { pages_visited: string }) => {
              metric.pages_visited = getPathFromUrl(metric.pages_visited);
              return metric;
            });
            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Visitor Log: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Visitor Log: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: { fullname: string }) => obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      filterGroupValue(dataArray, true, { userId: '', fullname: '', filter: false });
    } else {
      filterGroupValue(filterArr, true, { userId: '', fullname: '', filter: false });
    }
    setFilterVal(evt.target.value);
  };

  return (
    <div className="usage-metric visitor-log">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter User Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>
      <div className="metric-header no-left-field color-theme">
        <label className="">New Visitors : {[...new Set(usageTrackingData.map(item => item.fullname))]?.length}</label>
      </div>

      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table new-visitor-table">
              <thead>
                <tr>
                  {columns.map((data, indx: number) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default VisitorLog;
