export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Device Type',
    accessor: 'device_type',
  },
  {
    Header: 'Device Model',
    accessor: 'devicemodel',
  },
  {
    Header: 'Operating system',
    accessor: 'operatingsystem',
  },
  {
    Header: 'Browser',
    accessor: 'browser',
  },
  {
    Header: 'Visits',
    accessor: 'no_of_visits',
  },
];
