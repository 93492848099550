import React, { useEffect, useState, useMemo, ReactNode } from 'react';
import './index.scss';
import { apiResponse, rowSpanning } from '../../../utility/commonMethods';
import { visit_Table_Header } from './visitTableHeader';
import { Button, LineChartComponent, Modal, Loader } from '@gitlab-rtsensing/component-library';
import { workDayRedirect } from '../../../utility/commonMethods';
import MATOMO from '../../../assets/icons/matomo';
import WORKDAY from '../../../assets/icons/workday';
import { profileMatamo } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import ConfirmationBox from '../../form-components/confirm-box';
import { getUserDetailsFromOktaToken } from '../../../utility/commonMethods';
import { RequestAccessSubmitted } from '../../access-request-pages/access-request-submitted';

interface SearchModalProps {
  searchModalClosed: () => void;
  userID?: string;
  isQuickAdd?: boolean;
}

export const SearchByUser = (props: SearchModalProps): JSX.Element => {
  const { searchModalClosed, userID, isQuickAdd } = props;
  const columns = useMemo(() => visit_Table_Header, []);

  const [searchID, setSearchID] = useState(userID);
  const [showInfo, setShowInfo] = useState(false);
  const [userInfo, setUserInfo] = useState<any>([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [noDataMsg, setNoDataMsg] = useState(false);
  const [cnfLoader, setCnfLoader] = useState(false);
  const [cnfModal, setCnfModal] = useState(false);
  const [removeADGroup, setRemoveADGroup] = useState([]);
  const [availableADgroup, setAvailableADgroup] = useState<{ value: string; style: string }[]>([]);
  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);
  const removed_by_user = getUserDetailsFromOktaToken();
  const [isLoading, setLoading] = useState(false);
  const [requestSubmittedMsg, setRequestSubmittedMsg] = useState('');
  const [userADGroup, setUserADGroup] = useState<{ value: string; style: string }[]>([]);
  const [selectedAddADGroup, setSelectedAddADGroup] = useState('');
  const [selectedRmvADGroup, setSelectedRmvADGroup] = useState('');
  const [availableOrgADgroup, setAvailableOrgADgroup] = useState<string[]>([]);
  const [orgUserADGroupList, setOrgUserADGroupList] = useState<{ value: string; style: string }[]>([]);
  const [orgAvailableADgroupList, setOrgAvailableADgroupList] = useState<{ value: string; style: string }[]>([]);
  const [filterUserADGroup, setFilterUserADGroup] = useState('');
  const [filterAvailableADgroup, setFilterAvailableADgroup] = useState('');

  const userDetails = getUserDetailsFromOktaToken();

  useEffect(() => {
    setSearchID('');
    setShowInfo(false);
    setUserInfo([]);
    setNoDataMsg(false);
    setRemoveADGroup([]);
    setAccessRequestSuccessful(false);
  }, [searchModalClosed]);

  useEffect(() => {
    if (userID !== undefined && userID !== '') {
      setSearchID(userID);
      searchUser();
    }
  }, [userID]);

  useEffect(() => {
    if (isQuickAdd && userInfo?.list_ad) {
      getADGroupList();
    }
  }, [isQuickAdd, userInfo]);

  const searchUser = async () => {
    setNoDataMsg(false);
    let payload: any;
    if (searchID !== '' && searchID !== undefined) {
      payload = {
        user_id: searchID,
      };
    } else if (userID !== '' && userID !== undefined) {
      payload = {
        user_id: userID,
      };
    } else {
      toast.error('Please enter userID');
      return;
    }
    setBtnLoading(true);
    try {
      const res = await apiResponse('post', 'get-user-info', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setBtnLoading(false);
        const user_details = res.data.data.user_details;

        if (user_details.length < 1) {
          setSearchID('');
          setUserInfo([]);
          setShowInfo(false);
          setNoDataMsg(true);
        } else {
          let adGrpArr = user_details.list_ad.split(',').map((adGrp: string) => adGrp.trimStart());
          user_details.list_ad = user_details.list_ad === '' ? [] : adGrpArr;
          user_details.visit_detail = rowSpanning(user_details.visit_detail, 'lastvisit');
          setUserInfo(user_details);
          setShowInfo(true);
          if (isQuickAdd) {
            const quickAddOption = user_details?.list_ad.map((name: string) => {
              return { value: name, style: '' };
            });
            setUserADGroup(quickAddOption);
            setOrgUserADGroupList(quickAddOption);
            setSelectedAddADGroup('');
            setSelectedRmvADGroup('');
            setAvailableOrgADgroup([]);
            setOrgAvailableADgroupList([]);
            setFilterUserADGroup('');
            setFilterAvailableADgroup('');
            setAvailableADgroup([]);
          }
        }
      } else {
        setNoDataMsg(true);
        setShowInfo(false);
        setBtnLoading(false);
      }
    } catch (error) {
      console.log(error);
      setBtnLoading(false);
    }
  };

  function renderTableData(): ReactNode {
    var currentLastvisit = '';
    return userInfo.visit_detail.map((data: any, indx: any) => {
      return (
        <tr key={indx}>
          {columns.map((colData: any, colIndx: any) => {
            if (colData.accessor === 'visit_date') {
              if (data.row_span_count > 1) {
                currentLastvisit = data.lastvisit;
                if (colData.accessor === 'visit_date') {
                  return (
                    <td rowSpan={data.row_span_count} key={indx + colIndx}>
                      <span>{data[colData.accessor]}</span>
                    </td>
                  );
                }
              } else if (data.row_span_count === 1 && currentLastvisit === data.lastvisit) {
                return <React.Fragment key={indx + colIndx}></React.Fragment>;
              } else if (colData.accessor === 'visit_date') {
                return (
                  <td rowSpan={data.row_span_count} key={indx + colIndx}>
                    <span>{data[colData.accessor]}</span>
                  </td>
                );
              } else {
                return <td key={indx + colIndx}>{data[colData.accessor]} </td>;
              }
            } else {
              return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
            }
          })}
        </tr>
      );
    });
  }

  const confirmRemoveUser = async () => {
    setCnfModal(true);
  };

  const removeUser = async () => {
    setCnfLoader(true);
    const payload = {
      ad_group: userInfo.list_ad.toString(),
      user_id: userInfo.login_name,
      remove_by: removed_by_user.userName,
    };
    try {
      console.log(payload);
      const res = await apiResponse('post', 'remove-user-all-adgroup', [], payload);
      if (res?.data.status === 'SUCCESS') {
        setCnfModal(false);
        toast.info('User Removal Request has been Submitted, it will take 5 min to reflect.');
        setCnfLoader(false);
      }
    } catch (error) {
      console.log(error);
      setCnfModal(false);
      setCnfLoader(false);
    }
  };

  const getADGroupList = async () => {
    try {
      const res = await apiResponse('post', 'get-ad-group-list', [], []);
      if (res?.data.data.allowed_ad) {
        //for removing already added AD-groups
        const availableList = res?.data.data.allowed_ad.filter((name: string) => !userInfo?.list_ad.includes(name));
        setAvailableOrgADgroup(availableList);
        const updateList = availableList.map((name: string) => {
          return { value: name, style: '' };
        });
        setAvailableADgroup(updateList);
        setOrgAvailableADgroupList(updateList);
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitAccessRequest = async () => {
    let addUser: string[] = [];
    orgUserADGroupList.map((group_name: { value: string; style: string }) => {
      if (group_name?.style !== '') {
        return addUser.push(group_name.value);
      }
    });

    const removeUser: string[] = [];
    orgAvailableADgroupList.map((group_name: { value: string; style: string }) => {
      if (group_name?.style !== '') {
        return removeUser.push(group_name.value);
      }
    });
    if (addUser.length < 1 && removeUser.length < 1) {
      toast.error('No Ad-group is selected for removal or adding, please select Ad-group');
      return;
    }
    const payload = {
      add_ad_group: addUser.toString(),
      remove_ad_group: removeUser.toString(),
      user_id: searchID,
      update_by: userDetails?.email.split('@')[0],
    };
    setLoading(true);
    try {
      const res = await apiResponse('post', 'quick-add-user', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        setLoading(false);
        setAccessRequestSuccessful(true)
        setShowInfo(false)
        setRequestSubmittedMsg(res?.data?.data);
      } else {
        setLoading(false);
        setRequestSubmittedMsg('Failed to submit request');
      }
    } catch (error) {
      setRequestSubmittedMsg('Something Went Wrong!!');
      setLoading(false);
      console.log(error);
    }
  };

  const handleAddADgroup = (selected: string) => {
    let selectedGroups = userADGroup.filter(newAdGroup => !userInfo.list_ad.includes(newAdGroup.value));
    if (selected === '') {
      return;
    } else if (selectedGroups.length > 2 && !userInfo?.list_ad.includes(selected)) {
      toast.warning('Max. 3 AD-Group can be Added or Removed');
      return;
    }
    let add_ADGroup = orgUserADGroupList;
    if (userInfo?.list_ad.includes(selected)) {
      add_ADGroup.push({ value: selected, style: '' });
    } else {
      add_ADGroup.unshift({ value: selected, style: 'add-adgroup' });
    }
    const unique_ADGroup = add_ADGroup.filter((value, index, self) => index === self.findIndex(t => t.value === value.value));
    setUserADGroup([...unique_ADGroup]);
    setOrgUserADGroupList([...unique_ADGroup]);
    const availableList = orgAvailableADgroupList.filter((name: any) => name.value !== selected);
    setAvailableADgroup([...availableList]);
    setOrgAvailableADgroupList([...availableList]);
  };

  const handleRemoveADgroup = (selected: string) => {
    let selectedGroups = availableADgroup.filter(newAdGroup => !availableOrgADgroup.includes(newAdGroup.value));
    if (selected === '') {
      return;
    } else if (selectedGroups.length > 2 && !availableOrgADgroup.includes(selected)) {
      toast.warning('Max. 3 AD-Group can be Added or Removed');
      return;
    }
    let remove_AdGroup = orgAvailableADgroupList;
    if (availableOrgADgroup.includes(selected)) {
      remove_AdGroup.push({ value: selected, style: '' }); // for handling already present AD-group
    } else {
      remove_AdGroup.unshift({ value: selected, style: 'remove-adgroup' });
    }
    const unique_ADGroup = remove_AdGroup.filter((value, index, self) => index === self.findIndex(t => t.value === value.value)); //For not duplicating ad-group
    setAvailableADgroup([...unique_ADGroup]);
    setOrgAvailableADgroupList([...unique_ADGroup]);

    const selectedList = orgUserADGroupList.filter((name: any) => name.value !== selected);
    setUserADGroup([...selectedList]);
    setOrgUserADGroupList([...selectedList]);
  };

  const filterAvailableAdList = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const dataArray = orgAvailableADgroupList;
    let filterArr = dataArray.filter((obj: any) => obj.value.indexOf(evt.target.value) > -1);
    if (evt.target.value === '') {
      setAvailableADgroup(dataArray);
    } else {
      setAvailableADgroup(filterArr);
    }
    setFilterAvailableADgroup(evt.target.value);
  };

  const filterUserAdList = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const dataArray = orgUserADGroupList;
    let filterArr = dataArray.filter((obj: any) => obj.value.indexOf(evt.target.value) > -1);
    if (evt.target.value === '') {
      setUserADGroup(dataArray);
    } else {
      setUserADGroup(filterArr);
    }
    setFilterUserADGroup(evt.target.value);
  };

  return (
    <>
      {accessRequestSuccessful && isQuickAdd ? (
        <div>
          <RequestAccessSubmitted IsMessage={true} Message={requestSubmittedMsg} />
          <div className="close-modal">
            <Button className="close-btn" label="Close" type="secondary" onClick={searchModalClosed}></Button>
          </div>
        </div>
      ) : (
        <div className="user user-specific-info">
          <div className="user__search">
            <label className="user-audit__label ops-fs-4 ops-fw-bold">
              Login Id
              <sup className="sup-star">*</sup>
            </label>
            <div className="input-with-icon">
              <input
                onChange={e => setSearchID(e.target.value)}
                value={searchID}
                className="search-input"
                placeholder="Search User/login ID"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    searchUser();
                  }
                }}
              ></input>
              {btnLoading ? (
                <div className="user-audit-loader icon-loading"></div>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="icon-search"
                  viewBox="0 0 16 16"
                  onClick={searchUser}
                  data-testid={'icon-search'}
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
              )}
            </div>
          </div>

          {showInfo && (
            <div className="user__information">
              <div className="user__section">User Information</div>
              <div className="user__generic-info">
                <div className="">
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">User Details:</div>
                    <label className="user-audit__label ops-fs-4 user-name">
                      <span>
                        {userInfo.fullname} ( {userInfo.login_name})
                      </span>
                      <span className="faux-link" onClick={() => workDayRedirect(userInfo.profile_link)}>
                        <WORKDAY />
                      </span>
                      <span className="faux-link" onClick={() => profileMatamo(userInfo.visitor_id, 'day', '')}>
                        <MATOMO />
                      </span>{' '}
                      | {userInfo.employee_status}
                    </label>
                  </div>
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">Email:</div>
                    <label className="user-audit__label ops-fs-4" data-testid={userInfo.email}>
                      {userInfo.email}
                    </label>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">Position:</div>
                    <div className="user-audit__label ops-fs-4">{userInfo.position}</div>
                  </div>
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">Function:</div>
                    <div className="user-audit__label ops-fs-4">{userInfo.function}</div>
                  </div>
                </div>

                <div className="">
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">Supervisor:</div>
                    <div className="user-audit__label ops-fs-4">
                      {' '}
                      {userInfo.sup_name} ({userInfo.sup_id})
                    </div>
                  </div>
                  <div className="d-flex justify-content-flex-start">
                    <div className="user-audit__label ops-fs-4 ops-fw-bold">GCF Level:</div>
                    <div className="user-audit__label ops-fs-4">{userInfo.gcf_level}</div>
                  </div>
                </div>
              </div>

              {!isQuickAdd ? (
                <div>
                  <div className="user__section">User Ad Group Information</div>
                  <div className="user__adgrp">
                    {userInfo.list_ad.length === 0 ? (
                      <div className="d-flex justify-content-center width-100">
                        <label>User is not associated with any Sensing AD Group</label>
                      </div>
                    ) : (
                      <>
                        {userInfo.list_ad.map((userGrp: any, indx: any) => {
                          return (
                            <div className="user-groups" title={userGrp} key={indx}>
                              {userGrp}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="user__section">Visit Information</div>
                  <div className="user__visit-date">
                    <div className="visit-date--first">
                      <label className="ops-fs-4 ops-fw-bold">First Visit: </label>
                      <div className="ops-fs-4 date-value "> {userInfo.first_visit_date}</div>
                    </div>
                    <div className="visit-date--last">
                      <label className="ops-fs-4 ops-fw-bold">Last Visit: </label>
                      <div className="ops-fs-4 date-value">{userInfo.last_visit_date}</div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-space-between visit-container">
                    {userInfo.visit_detail.length === 0 ? (
                      <p className="table-msg"> No Information Available</p>
                    ) : (
                      <div className="visit-info">
                        <label className="user-audit__label ops-fs-4 ops-fw-bold">Last ten visits details</label>
                        <div className="visit-table ">
                          <table>
                            <thead>
                              <tr>
                                {columns.map((data: any, indx: any) => {
                                  return <th key={indx}>{data.Header}</th>;
                                })}
                              </tr>
                            </thead>
                            <tbody>{renderTableData()}</tbody>
                          </table>
                        </div>
                      </div>
                    )}
                    {userInfo.core_member.length === 0 ? (
                      <div className="d-flex justify-content-center width-100">
                        <label>Visit information is not available</label>
                      </div>
                    ) : (
                      <div className="visit-info">
                        <label className="user-audit__label ops-fs-4 ops-fw-bold">Visits: Last 4 Months</label>
                        <div className="visit-chart">
                          <LineChartComponent
                            data={userInfo.core_member}
                            dataKeys={[
                              {
                                color: '#3773d2',
                                label: 'number_of_visits',
                              },
                            ]}
                            useTooltip={true}
                            showLegend
                            title="User Visit Information"
                            xAxisKey="week_start_date"
                            yAxisWidth={40}
                            chartHeight={250}
                            tick={true}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      label="Remove User From All AD Groups"
                      disabled={userInfo.list_ad.length === 0}
                      type="primary"
                      onClick={() => confirmRemoveUser()}
                      className="rmv-btn"
                    ></Button>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="user__section">
                    <div>Update User Access</div>
                    <div className="non-filter-note">
                      <p>* Max. 3 AD-Group can be Added or Removed </p>
                    </div>
                  </div>
                  {/* <div className='quick-note'>*User will be remove from left red highlighted group and will get access in right green highlighted group.</div> */}
                  <div className="dual-list">
                    <div id="dualListwrapper">
                      <div>
                        <input
                          className="dual-list-filter"
                          value={filterAvailableADgroup}
                          onChange={e => filterAvailableAdList(e)}
                          placeholder="Add user to AD-group"
                        />
                        <select className="dual-select" size={10} id="from" onChange={e => setSelectedAddADGroup(e.target.value)}>
                          {availableADgroup.map((option: any) => (
                            <option className={`option-style ${option.style}`} key={option.value} value={option.value}>
                              {option.value}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="dual-list-controls">
                        <button onClick={() => handleAddADgroup(selectedAddADGroup)}>►</button>
                        <button onClick={() => handleRemoveADgroup(selectedRmvADGroup)}>◄</button>
                      </div>
                      <div>
                        <input
                          className="dual-list-filter"
                          value={filterUserADGroup}
                          onChange={e => filterUserAdList(e)}
                          placeholder="Remove user from AD-group"
                        />
                        <select className="dual-select" size={10} onChange={e => setSelectedRmvADGroup(e.target.value)}>
                          {userADGroup.map((option: any) => (
                            <option className={`option-style ${option.style}`} key={option.value} value={option.value}>
                              {option.value}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="quick-add-btn">
                    <Button
                      label="Submit"
                      disabled={isLoading}
                      type="primary"
                      icon={isLoading ? <div className="user-audit-loader icon-loading"></div> : undefined}
                      onClick={() => submitAccessRequest()}
                    ></Button>
                  </div>
                </div>
              )}
            </div>
          )}
          {noDataMsg && (
            <div className="d-flex justify-content-center align-items-center noMsg">
              <label>User does not exist. Please try again, with correct user/login ID.</label>
            </div>
          )}
        </div>
      )}
      <Modal className="cnf-modal" opened={cnfModal} onClose={() => setCnfModal(false)} disableBackdropDismiss={true}>
        <Modal.Header contentClassName="Remove User">
          <Modal.Title title={'Remove User'} className="cnf-title" />
        </Modal.Header>
        {cnfLoader ? (
          <div className="adm-loader-container">
            <Loader />
          </div>
        ) : (
          <ConfirmationBox userInfo={userInfo.login_name} groupName={'All'} onConfirm={removeUser} onCancel={() => setCnfModal(false)} />
        )}
      </Modal>
    </>
  );
};

export default SearchByUser;
