export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Page Title',
    accessor: 'page_title',
  },
  {
    Header: 'URL',
    accessor: 'url',
  },
  {
    Header: 'Average Time Spent',
    accessor: 'average_timespent',
  },
  // {
  //   Header: "Date",
  //   accessor: "servertimepretty",
  // },
];
