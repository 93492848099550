export const usageTrackingHeader = [
  {
    Header: 'User Name',
    accessor: 'fullname',
  },
  {
    Header: 'User ID',
    accessor: 'userid',
  },
  {
    Header: 'Entry Date',
    accessor: 'insert_date',
  },
  {
    Header: 'From URL',
    accessor: 'from_url',
  },
  {
    Header: 'Time Spent',
    accessor: 'timespent',
  },
];
