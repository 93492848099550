import { useMemo } from 'react';
import { useState, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { timeToHrMinSecond } from '../utility';
import ExternalLinkLogo from '../../../assets/icons/external-link';
import { PageLevelUsageProps } from '../usage-metric-interface';
import YRotate from '../../../assets/images/YRotate.png';
import PageUsageChart from '../kpi-at-top/page-usage-chart';

const PageLevelUsage = (props: PageLevelUsageProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState([]);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const [flipButtonText, setflipButtonText] = useState('Go To Table View');
  const [filterInVisible, setFilterInvisible] = useState(false);

  const [dateValue, setDateValue] = useState({
    type: props.date.type,
    fromDate: props.date.fromDate,
    toDate: props.date.toDate,
    matamoType: 'day',
    matamoDate: props.date.toDate,
    displayValue: props.date.displayValue,
  });

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '13',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Page Level Usage: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: any, b: any) => {
              if (Number(a.count_visits) > Number(b.count_visits)) {
                return -1;
              } else if (Number(a.count_visits) < Number(b.count_visits)) {
                return 1;
              } else {
                return 0;
              }
            });
            res.data.data.user_metric = res.data.data.user_metric.map((obj: any) => {
              return Object.assign(
                obj,
                {
                  page_title: obj.page_title.indexOf('?') > -1 ? obj.page_title.split('?')[0] : obj.page_title,
                },
                { totaltimespent: timeToHrMinSecond(obj.totaltimespent) },
              );
            });
            setUsageTrackingOriginal(res.data.data.user_metric);
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Page Level Usage: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Page Level Usage: Something Went Wrong!!');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    setUsageTrackingData(filterGroupValue([]));
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: any) => {
    if (dataArray.length === 0) {
      dataArray = usageTrackingOriginal;
    }
    const filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    let dataList: any = []; //for merging core-member and non-core member data
    filteredData.map((value: any, index: any, self: any) => {
      if (index === self.findIndex((t: any) => t.page_title == value.page_title)) {
        dataList.push({
          count_visits: value.count_visits,
          totaltimespent: value.totaltimespent,
          uniqueusers: value.uniqueusers,
          page_title: value.page_title,
        });
      } else {
        dataList.map((data: any) => {
          if (data.page_title === value.page_title) {
            data.count_visits = Number(data.count_visits) + Number(value.count_visits);
          }
        });
      }
    });
    if (dataList.length === 0) {
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      setFilterDisabled(false);
    }
    return dataList;
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const dataArray = filterGroupValue([]);
    let filterArr = dataArray.filter((obj: any) => obj.page_title.indexOf(evt.target.value) > -1);
    if (evt.target.value === '') {
      setUsageTrackingData(dataArray);
    } else {
      setUsageTrackingData(filterArr);
    }
    setFilterVal(evt.target.value);
  };

  const externalMatamo = (url: string) => {
    let matamoLink =
      'https://matomo-ext.devops.amgen.com/index.php?module=CoreHome&category=General_Visitors&subcategory=Live_VisitorLog&action=index&idSite=' +
      process.env.REACT_APP_MATAMO_ID +
      '&segment=pageUrl==' +
      url;

    if (dateValue.matamoType === 'range') {
      matamoLink += '&period=range&date=' + dateValue.matamoDate;
    } else if (dateValue.matamoType === 'day') {
      matamoLink += '&period=day&date=' + dateValue.matamoDate;
    }
    window.open(matamoLink, '_blank');
  };

  const flipButtonClick = () => {
    let flipPanel = document.querySelector('.page-level-usage .flip-panel');

    if (flipPanel?.classList.contains('rotate')) {
      flipPanel?.classList.remove('rotate');
      setFilterInvisible(false);
      setflipButtonText('Go To Table View');
    } else {
      flipPanel?.classList.add('rotate');
      setflipButtonText('Go To Chart View');
      setFilterInvisible(true);
    }
  };

  return (
    <div className="usage-metric page-level-usage">
      <div className="metric-header no-left-field">
        <div className="filters">
          {filterInVisible ? (
            <input
              type="text"
              className="metric-input metric-filter"
              value={filterVal}
              onChange={evt => {
                filterRows(evt);
              }}
              placeholder={'Filter Page'}
            ></input>
          ) : null}
        </div>
        <div className="flip-button" onClick={() => flipButtonClick()}>
          <span>{flipButtonText}</span>
          <img src={YRotate} alt="flip-icon"></img>
        </div>
      </div>
      <div className="flip-panel">
        <div className="flip-panel-inner">
          <div className="front-panel">
            <PageUsageChart
              date={props.date}
              clearData={props.clearData}
              setClearData={props.setClearData}
              userGroupValue={props.userGroupValue}
              includeCoreMember={props.includeCoreMember}
            />
          </div>
          <div className="back-panel">
            {isLoading ? (
              <div className="adm-loader-container">
                <Loader />
              </div>
            ) : (
              <div className="table-container usage-metric-container-page">
                {usageTrackingData.length === 0 ? (
                  <p className="table-msg">{tableMsg}</p>
                ) : (
                  <table className="usage-metric-table page-level-usage">
                    <thead>
                      <tr>
                        {columns.map((data: any, indx: any) => {
                          return <th key={indx}>{data.Header}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {usageTrackingData.map((data: any, indx: any) => {
                        return (
                          <tr key={indx}>
                            {columns.map((colData: any, colIndx: any) => {
                              if (colData.accessor === 'url') {
                                return (
                                  <td key={indx + colIndx} onClick={() => externalMatamo(data[colData.accessor])}>
                                    <span className="page-url">{data[colData.accessor]}</span>
                                    <span className="external-link">
                                      <ExternalLinkLogo />
                                    </span>
                                  </td>
                                );
                              } else {
                                return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                              }
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageLevelUsage;
