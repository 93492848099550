import { useState, useEffect, useMemo, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, profileMatamo, workDayRedirect, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import IOS from '../../../assets/icons/ios';
import WindowOS from '../../../assets/icons/window-os';
import MacEntryModeIcon from '../../../assets/icons/mac-entry-mode-icon';
import { AppContext } from '../../../contexts/app-context';
import Profile from '../../../assets/images/Profile.png';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import ExternalLinkLogo from '../../../assets/icons/external-link';

const EntryModeTracking = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);

  useEffect(() => {
    if (props.clearData) {
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      setFilterVal('');
      getUsageTrackingData();
    }
  }, [props.clearData, props.includeCoreMember]);

  useEffect(() => {
    document.querySelectorAll('[class*="filter-"] > .selected').forEach(obj => {
      if (obj !== null) {
        obj.classList.remove('selected');
      }
    });
  }, [isFilterDisabled]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.userGroupValue, props.includeCoreMember]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
      setFilterDisabled(true);
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      //All Other case
      setFilterDisabled(false);
    }
    setUsageTrackingData(filteredData);
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '5',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Entry Mode Tracking: Data not available!!');
          } else {
            res.data.data.user_metric.sort((a: { no_of_visits: string }, b: { no_of_visits: string }) => {
              if (Number(a.no_of_visits) > Number(b.no_of_visits)) {
                return -1;
              } else if (Number(a.no_of_visits) < Number(b.no_of_visits)) {
                return 1;
              } else {
                return 0;
              }
            });
            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Entry Mode Tracking: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Entry Mode Tracking: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: { fullname: string }) => obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1);
    if (evt.target.value === '') {
      filterGroupValue(dataArray, true, { userId: '', fullname: '', filter: false });
    } else {
      filterGroupValue(filterArr, true, { userId: '', fullname: '', filter: false });
    }

    document.querySelectorAll('[class*="filter-"] > .selected').forEach(obj => {
      if (obj !== null) {
        obj.classList.remove('selected');
      }
    });
    setFilterVal(evt.target.value);
  };

  const onIOSFilterClick = (evt: React.MouseEvent) => {
    if (isFilterDisabled) return;

    if ((evt.target as HTMLButtonElement).classList.contains('selected')) {
      resetFilter(evt.target as HTMLButtonElement);
    } else {
      setFilter(evt.target as HTMLButtonElement, 'ios');
    }
  };

  const onWindowOSClick = (evt: React.MouseEvent) => {
    if (isFilterDisabled) return;

    if ((evt.target as HTMLButtonElement).classList.contains('selected')) {
      resetFilter(evt.target as HTMLButtonElement);
    } else {
      setFilter(evt.target as HTMLButtonElement, 'windows');
    }
  };

  const onMACFilterClick = (evt: React.MouseEvent) => {
    if (isFilterDisabled) return;

    if ((evt.target as HTMLButtonElement).classList.contains('selected')) {
      resetFilter(evt.target as HTMLButtonElement);
    } else {
      setFilter(evt.target as HTMLButtonElement, 'mac');
    }
  };

  const resetFilter = (obj: HTMLButtonElement) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    (obj as HTMLButtonElement).classList.remove('selected');
    setUsageTrackingData(dataArray);
    setFilterVal('');
  };

  const setFilter = (obj: HTMLButtonElement, key: string) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter((obj: { operatingsystem: string }) => obj.operatingsystem.toLowerCase().indexOf(key) > -1);
    document.querySelectorAll('[class*="filter-"] > .selected').forEach(obj => {
      if (obj !== null) {
        obj.classList.remove('selected');
      }
    });
    obj.classList.add('selected');
    setUsageTrackingData(filterArr);
    setFilterVal('');
  };

  return (
    <div className="usage-metric entry-mode-tracking">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <span className={isFilterDisabled ? 'filter-ios disabled' : 'filter-ios'} onClick={onIOSFilterClick}>
            <IOS />
          </span>
          <span className={isFilterDisabled ? 'filter-mac disabled' : 'filter-mac'} onClick={onMACFilterClick}>
            <MacEntryModeIcon />
          </span>
          <span className={isFilterDisabled ? 'filter-windows disabled' : 'filter-windows'} onClick={onWindowOSClick}>
            <WindowOS />
          </span>
          <input
            type="text"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter User Name'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>

      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table entry-mode-tracking">
              <thead>
                <tr>
                  {columns.map((data, indx: number) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {usageTrackingData.map((data, indx: number) => {
                  return (
                    <tr key={indx}>
                      {columns.map((colData, colIndx: number) => {
                        if (colData.accessor === 'fullname') {
                          return (
                            <td
                              className="faux-link"
                              key={indx + colIndx}
                              onClick={() => profileMatamo(data['visitorid'], props.date.matamoType, props.date.matamoDate)}
                            >
                              <span className="user-name">{data[colData.accessor]}</span>
                              <span className="external-link">
                                <img src={Profile} alt="profile-link" className="profile" />
                              </span>
                            </td>
                          );
                        } else if (colData.accessor === 'userid') {
                          return (
                            <td className="faux-link" key={indx + colIndx} onClick={() => workDayRedirect(data.profiledeeplink)}>
                              <span className="user-id">{data[colData.accessor]}</span>
                              <span className="external-link">
                                <ExternalLinkLogo />
                              </span>
                            </td>
                          );
                        } else {
                          return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default EntryModeTracking;
