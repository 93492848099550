import React, { useState, useEffect, useContext } from 'react';
import { DateRangePicker } from 'react-date-range';
import { addDays, format, differenceInDays, endOfToday, parseISO, endOfYesterday } from 'date-fns';
import { Button, Select, Table } from '@opsdti-global-component-library/amgen-design-system';
import './index.scss';
import { apiResponse } from '../../utility/commonMethods';
import { toast } from 'react-toastify';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell, LineChart, Line, Customized, Rectangle } from 'recharts';
import { Loader } from '@gitlab-rtsensing/component-library';
import { convertCamelCaseToTitleCase } from '../../utility/commonMethods';
import FeebackDetailTable from './feedbackRecordTable';
import { AppContext } from '../../contexts/app-context';

const SensingGptUserFeedback = () => {
  const dateFormat = 'yyyy-MM-dd';
  const [dateVisibility, setdateVisibility] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const minDate = '2022-12-15'; //This is as per discussion on 20Apr23, for the last date range
  const chartFontSize = 13;
  const fromDate = format(addDays(new Date(), -7), dateFormat);
  const toDate = format(endOfToday(), dateFormat);
  const [dateValue, setDateValue] = useState({
    type: 'range',
    fromDate: fromDate,
    toDate: toDate,
    displayValue: 'From: ' + format(addDays(new Date(), -7), 'MM-dd-yyyy') + ' To: ' + format(endOfToday(), 'MM-dd-yyyy'),
  });
  const [state, setState] = useState({
    selection: {
      startDate: new Date(),
      endDate: addDays(new Date(), -7),
      key: 'selection',
    },
    compare: {
      startDate: new Date(),
      endDate: addDays(new Date(), 0),
      key: 'compare',
    },
  });

  const [feedbackDetail, setFeedbackDetail] = useState([]);
  const [feedbackDetailOriginal, setFeedbackDetailOriginal] = useState([]);
  const [feedbackData, setFeedbackData] = useState([]);
  const [feedbackDataOriginal, setFeedbackDataOriginal] = useState([]);
  const [totalFeedback, setTotalFeedback] = useState([]);
  const [filterDomain, setFilterDomain] = useState('All');
  const [filterType, setFiltertype] = useState('');
  const [defaultbarColor, setDefaultbarColor] = useState('');
  const [totalHeaderVal, setTotalHeaderVal] = useState({
    count_of_records: 0,
    positive_user_rating: 0,
    negative_user_rating: 0,
    user_rating_provided: 0,
  });
  const [internalUserCheck, setInternalUserCheck] = useState(true);
  const [selectedUserGroup, setSelectedUserGroup] = useState('All Sensing Users');
  const [filterDomainOption, setFilterDomainOption] = useState<{ label: string; value: string }[]>([{ label: 'All', value: 'All' }]);
  const horizontalShift = 20; // Adjust this value to shift horizontally
  const { userGroups, setUserGroups } = useContext(AppContext);
  const [internalUserDisable, setInternalUserDisable] = useState(true);

  const onDataInputClick = () => {
    setdateVisibility(!dateVisibility);
  };

  useEffect(() => {
    getSensingGPTFeedbackData();
    getSensingGPTFeedbackDetail();
  }, []);

  useEffect(() => {
    if (internalUserCheck && feedbackDataOriginal.length > 0) {
      setFeedbackData(
        feedbackDataOriginal
          .filter((data: any) => data.domains !== 'Total' && data.core_member_flag === 'All' && data.group_name === selectedUserGroup)
          .sort((data1: any, data2: any) => data2.user_rating_provided - data1.user_rating_provided),
      );
      setTotalFeedback(
        feedbackDataOriginal.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'All' && data.group_name === selectedUserGroup),
      );
      setTotalHeaderVal(
        feedbackDataOriginal.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'All' && data.group_name === selectedUserGroup)[0],
      );
    } else if (!internalUserCheck && feedbackDataOriginal.length > 0) {
      setFeedbackData(
        feedbackDataOriginal.filter((data: any) => data.domains !== 'Total' && data.core_member_flag === 'N' && data.group_name === selectedUserGroup),
      );
      setTotalFeedback(
        feedbackDataOriginal.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'N' && data.group_name === selectedUserGroup),
      );
      setTotalHeaderVal(
        feedbackDataOriginal.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'N' && data.group_name === selectedUserGroup)[0],
      );
    }
    //setFeedbackData(feedbackData.map(data => data).sort((data1: any, data2: any) => data2.user_rating_provided - data1.user_rating_provided)); //For bar click to show proper color
  }, [internalUserCheck, selectedUserGroup]);

  useEffect(() => {
    window.addEventListener('click', onWindowClick);
    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  useEffect(() => {
    if (filterDomain === 'All') {
      setFeedbackDetail(feedbackDetailOriginal);
    } else {
      if (filterType === '') {
        setDefaultbarColor('');
        setFeedbackDetail(feedbackDetailOriginal.filter((data: any) => data.primary_domain === filterDomain));
      } else {
        setDefaultbarColor('rgba(0, 133, 51, 0.6)');
        setFeedbackDetail(
          feedbackDetailOriginal.filter(
            (data: any) =>
              data.primary_domain === filterDomain &&
              data.user_feedback_rating === (filterType === 'positive_user_rating' ? 'Positive' : filterType === 'negative_user_rating' ? 'Negative' : ''),
          ),
        );
      }
    }
  }, [filterDomain, filterType]);

  useEffect(() => {
    if (userGroups.length === 0) {
      getUserGroups();
    }
  }, [userGroups]);
  
  useEffect(() => {
    if (selectedUserGroup === 'All Sensing Users') {
      setInternalUserDisable(true);
    } else {
      setInternalUserDisable(false);
      setInternalUserCheck(false);
    }
  }, [selectedUserGroup]);

  const onWindowClick = (e: any) => {
    if (e.target?.classList.contains('search-input') || e.target?.closest('.date-container')) {
    } else {
      setdateVisibility(false);
    }
  };

  const getSensingGPTFeedbackData = async () => {
    setLoading(true);
    try {
      const res = await apiResponse('get', 'get-sensing-gpt-user-feedback', [dateValue.fromDate, `to_date=${dateValue.toDate}&date_type=${dateValue.type}`], []);
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Feedback Information: Data not available!!');
          setFeedbackData([]);
          setFeedbackDataOriginal([]);
          setTotalFeedback([]);
          setTotalHeaderVal({
            count_of_records: 0,
            positive_user_rating: 0,
            negative_user_rating: 0,
            user_rating_provided: 0,
        });
        } else {
          const Feedback = res.data.data.map((data: any) => {
            data.total_cost = data.total_cost.toFixed(2);
            data.domains = data.domains === null || data.domains === 'Not Available' ? 'Not Available' : convertCamelCaseToTitleCase(data.domains);
            data.max_execution_time = data.max_execution_time === null ? '' : parseFloat(data.max_execution_time.toFixed(2));
            data.min_execution_time = data.min_execution_time === null ? '' : parseFloat(data.min_execution_time.toFixed(2));
            data.avg_execution_time = data.avg_execution_time === null ? '' : parseFloat(data.avg_execution_time.toFixed(2));
            return data;
          });
          setFeedbackDataOriginal(Feedback);
          setFeedbackData(
            Feedback.filter((data: any) => data.domains !== 'Total' && data.core_member_flag === 'All').sort(
              (data1: any, data2: any) => data2.user_rating_provided - data1.user_rating_provided,
            ),
          );
          setTotalFeedback(Feedback.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'All'));
          setTotalHeaderVal(Feedback.filter((data: any) => data.domains === 'Total' && data.core_member_flag === 'All')[0]);

          //setFeedbackData(feedbackData.map(data => data).sort((data1: any, data2: any) => data2.user_rating_provided - data1.user_rating_provided)); //For bar click to show proper color
        }
      } else {
        console.log(res);
        toast.error('Feedback Information: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Feedback Information: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const getSensingGPTFeedbackDetail = async () => {
    setLoading(true);
    try {
      const res = await apiResponse('get', 'get-sensing-gpt-user-feedback-details', [dateValue.fromDate, `to_date=${dateValue.toDate}&date_type=${dateValue.type}`], {});
      if (res?.data?.status === 'SUCCESS') {
        if (res.data.data.length === 0) {
          toast.error('Feedback Information: Data not available!!');
          setFeedbackDetail([]);
          setFeedbackDetailOriginal([]);
        } else {
          const Feedback = res.data.data.map((data: any) => {
            data.question_date = typeof data.question_date !== 'string' ? '' : format(parseISO(data.question_date), 'MM-dd-yyyy');
            data.user_feedback_date = typeof data.feedback_date !== 'string' ? '' : format(parseISO(data.user_feedback_date), 'MM-dd-yyyy');
            data.user_feedback_rating = data.user_feedback_rating === 1 ? 'Positive' : data.user_feedback_rating === 0 ? 'Negative' : '';
            data.primary_domain = data.primary_domain === null ? 'Not Available' : convertCamelCaseToTitleCase(data.primary_domain);
            data.execution_time = data.execution_time === null ? '' : Number(data.execution_time).toFixed(2);
            data.fullname = [data.fullname, data.profile_deeplink];
            // data.total_cost = data.total_cost.toFixed(2);
            return data;
          });
          const domainOption = Feedback.map((data: any) => {
            return { value: data?.primary_domain, label: data?.primary_domain };
          }).filter((value: any, index: number, self: any) => self.map((x: any) => x.value).indexOf(value.value) === index);
          setFilterDomainOption([{ label: 'All', value: 'All' }, ...domainOption]);
          setFeedbackDetailOriginal(Feedback);
          setFeedbackDetail(Feedback);
        }
      } else {
        console.log(res);
        toast.error('Feedback Information: Failed to load!!');
      }
    } catch (error) {
      console.log(error);
      toast.error('Feedback Information: Something Went Wrong!!');
    }
    setLoading(false);
  };

  const onDateChange = (item: any) => {
    let dateObj: any = {};
    if (item.selection === undefined) return;

    if (differenceInDays(item.selection.startDate, item.selection.endDate) === 0) {
      if (differenceInDays(item.selection.startDate, endOfToday()) === 0) {
        dateObj.type = 'today';
        dateObj.toDate = '';
      } else if (differenceInDays(item.selection.startDate, endOfYesterday()) === 0) {
        dateObj.type = 'yesterday';
        dateObj.toDate = '';
      } else {
        dateObj.type = 'singleday';
        dateObj.toDate = format(item.selection.startDate, dateFormat);
      }
      dateObj.displayValue = format(item.selection.startDate, 'MM-dd-yyyy');
      dateObj.fromDate = '';
       } else {
      dateObj.type = 'range';
      dateObj.displayValue = 'From: ' + format(item.selection.startDate, 'MM-dd-yyyy') + ' To: ' + format(item.selection.endDate, 'MM-dd-yyyy');
      dateObj.fromDate = format(item.selection.startDate, dateFormat);
      dateObj.toDate = format(item.selection.endDate, dateFormat);
    }
    setDateValue(dateObj);
    setState({ ...state, ...item });
  };

  const applyFilters = () => {
    setdateVisibility(false);
    getSensingGPTFeedbackData();
    getSensingGPTFeedbackDetail();
  };

  const handleBarClick = (e: any) => {
    const selected_Domain = e.domains;
    if (selected_Domain === filterDomain && e.tooltipPayload[0].dataKey === filterType) {
      setFilterDomain('All');
      setFiltertype('');
    } else {
      setFilterDomain(selected_Domain);
      setFiltertype(e.tooltipPayload[0].dataKey);
    }
  };

  // using Customized gives you access to all relevant chart props
  const CustomizedRectangle = (props: any) => {
    const { formattedGraphicalItems } = props;

    // get first and second series in chart
    const firstSeries = formattedGraphicalItems[0];
    const secondSeries = formattedGraphicalItems[2];
    const medianSeries = formattedGraphicalItems[1];
    // render custom content using points from the graph
    return firstSeries?.props?.points.map((firstSeriesPoint: any, index: number) => {
      const secondSeriesPoint = secondSeries?.props?.points[index];
      const yDifference = firstSeriesPoint.y - secondSeriesPoint.y;
      const medianSeriesPoint = medianSeries?.props?.points[index];

      return (
        <g key={firstSeriesPoint.payload.name}>
          <line
            x1={secondSeriesPoint.x - 20 + horizontalShift}
            y1={medianSeriesPoint.y}
            x2={secondSeriesPoint.x + 40 + horizontalShift}
            y2={medianSeriesPoint.y}
            stroke="black"
            strokeWidth={2}
            strokeDasharray="2,2"
          />
          <Rectangle
            className={'custom-style'}
            key={firstSeriesPoint.payload.name}
            width={30}
            height={yDifference}
            x={secondSeriesPoint.x - 5 + horizontalShift}
            y={secondSeriesPoint.y}
            fill={'#0063c3'}
          />
        </g>
      );
    });
  };

  
  const getUserGroups = async () => {
    const payload = {};
    try {
      const res = await apiResponse('get', 'get-user-group', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.userGroups) {
          if (res.data.data.userGroups.length === 0) {
          } else {
            res.data.data.userGroups.sort((a: any, b: any) => {
              if (a < b) {
                return -1;
              } else if (a > b) {
                return 1;
              } else {
                return 0;
              }
            });
            setUserGroups(res.data.data.userGroups);
          }
        }
      } else {
        console.log(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="feedback-container">
      <div className="usage-metric usage-metric-header">
        {dateVisibility && (
          <DateRangePicker
            className="date-container"
            maxDate={addDays(new Date(), 0)}
            direction="vertical"
            showMonthAndYearPickers={true}
            showDateDisplay={false}
            showPreview={true}
            onChange={(item: any) => onDateChange(item)}
            onShownDateChange={(item: any) => onDateChange(item)}
            scroll={{ enabled: false }}
            editableDateInputs={true}
            ranges={[state.selection]}
            months={1}
            minDate={new Date(minDate)}
          />
        )}
        <div className="range-selection-apply">
          <input type="text" onClick={onDataInputClick} value={dateValue.displayValue} className="search-input date-filter range-picker" readOnly></input>
          <Button
            text="Apply"
            className="apply-btn"
            onClick={() => {
              applyFilters();
            }}
            type="primary"
          ></Button>
        </div>
        <div className="d-flex">
          <div className={!internalUserDisable ? 'internal-user disabled' : 'internal-user'}>
            <input
              type="checkbox"
              id="internal-user"
              checked={internalUserCheck}
              onChange={() => {
                setInternalUserCheck(!internalUserCheck);
              }}
              disabled={!internalUserDisable}
            ></input>
            <label htmlFor="internal-user" className="ops-text ops-text-dark ops-fw-bold ops-fs-4">
              Include Sensing Team Members
            </label>
          </div>
          <div className="user-group-multiselect">
            <span className="ops-text ops-text-dark ops-fw-bold ops-fs-4 ">User Group</span>
            <select
              onChange={e => {
                setSelectedUserGroup(e.target.value);
              }}
              value={selectedUserGroup}
            >
              {userGroups.map((option: string) => (
                <option className="option-style" key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div> 
      { totalHeaderVal?.count_of_records === undefined ? (
        <div className="adm-loader-container">
          <div className="no-data-message"><strong>No Data Available</strong></div>
        </div>
      ) : (
      <div>
      <div className="feeback-container">
        <div className="total-usage">
          <h4 className="total-usage-title">Total Q&A Usage </h4>
          <div className="count-container"> {totalHeaderVal.count_of_records === null ? 0 : totalHeaderVal.count_of_records}</div>
        </div>
        <div className="comparision-container">
          <h4 className="total-feedback"> Total Feedback Count </h4>
          <div className="count-container"> {totalHeaderVal.user_rating_provided === null ? 0 : totalHeaderVal.user_rating_provided}</div>
        </div>
        <div className="total-feedback">
          <h4 className="total-usage-title">Positive Feedback </h4>
          <div className="count-container"> {totalHeaderVal.positive_user_rating === null ? 0 : totalHeaderVal.positive_user_rating}</div>
        </div>
        <div className="total-feedback">
          <h4 className="total-usage-title">Negative Feedback </h4>
          <div className="count-container"> {totalHeaderVal.negative_user_rating === null ? 0 : totalHeaderVal.negative_user_rating}</div>
        </div>
      </div>
      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="domain-comparision">
          <div className="chart-container">
            <div className="chart-heading feedback-chart">
              <h5 className="feedback-chart__title">Domain's Feedback Comparison</h5>
            </div>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={feedbackData.map(data => data).sort((data1: any, data2: any) => data2.count_of_records - data1.count_of_records)}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={30}
              >
                <XAxis type="number" tick={{ fontSize: chartFontSize }} tickFormatter={tick => (Number.isInteger(tick) ? tick : '')} />
                <YAxis type="category" width={100} dx={0} dy={0} dataKey="domains" tick={{ fontSize: chartFontSize }} />
                <Tooltip contentStyle={{ fontSize: chartFontSize }} />
                <Bar dataKey="count_of_records" fill="#0063c3" name="Feedback Count" />
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-container">
            <div className="chart-heading">
              <h5>Execution Time Comparison</h5>
            </div>
            <ResponsiveContainer width="100%" height="90%">
              <LineChart
                data={feedbackData}
                margin={{
                  top: 5,
                  right: 50,
                  left: 20,
                  bottom: 5,
                }}
              >
                <XAxis
                  padding={{ left: 10, right: 20 }}
                  type="category"
                  dataKey="domains"
                  tick={{ fontSize: chartFontSize }}
                  tickFormatter={convertCamelCaseToTitleCase}
                />
                <YAxis tick={{ fontSize: chartFontSize }} />
                <Tooltip contentStyle={{ fontSize: chartFontSize }} />
                <Legend wrapperStyle={{ fontSize: chartFontSize, marginLeft: 30 }} />
                <Line type="monotone" dataKey="max_execution_time" stroke="#d62728" name="Maximum Execution Time (s)" strokeWidth={0} />
                <Line type="monotone" dataKey="avg_execution_time" stroke="#008533" name="Average Execution Time (s)" strokeWidth={0} />
                <Line type="monotone" dataKey="min_execution_time" stroke="#0063c3" name="Mininum Execution Time (s)" strokeWidth={0} />
                <Customized component={CustomizedRectangle} />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="adm-loader-container">
          <></>
        </div>
      ) : (
        <div className="domain-comparision">
          <div className="chart-container">
            <div className="chart-heading feedback-chart">
              <h5 className="feedback-chart__title">Positive Vs Negative Feedback per Domain</h5>
              <p className="feedback-chart__note">
                <sup>**</sup>Filter Feedback Details Table by Bar Select/Toggle
              </p>
            </div>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={feedbackData}
                layout="vertical"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                barSize={30}
              >
                <XAxis type="number" tick={{ fontSize: chartFontSize }} tickFormatter={tick => (Number.isInteger(tick) ? tick : '')} />
                <YAxis type="category" width={100} dx={0} dy={0} dataKey="domains" tick={{ fontSize: chartFontSize }} />
                <Tooltip contentStyle={{ fontSize: chartFontSize }} />
                <Legend wrapperStyle={{ fontSize: chartFontSize }} />
                <Bar dataKey="positive_user_rating" stackId="a" fill={'#0063c3'} name="Positive Feeback" onClick={handleBarClick} data-color={defaultbarColor}>
                  {feedbackData.map((entry: any, index: number) => (
                    <Cell
                      fill={filterDomain === entry.domains && filterType === 'positive_user_rating' && defaultbarColor !== '' ? defaultbarColor : '#0063c3'}
                      key={index}
                    />
                  ))}
                </Bar>
                <Bar dataKey="negative_user_rating" stackId="a" fill={'#BC4A3C'} name="Negative Feedback" onClick={handleBarClick} data-color={defaultbarColor}>
                  {feedbackData.map((entry: any, index: number) => (
                    <Cell
                      fill={filterDomain === entry.domains && filterType === 'negative_user_rating' && defaultbarColor !== '' ? defaultbarColor : '#BC4A3C'}
                      key={index}
                    />
                  ))}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
          <div className="chart-container">
            <div className="chart-heading">
              <h5>Cost in USD per Domain</h5>
            </div>
            <ResponsiveContainer width="100%" height="90%">
              <BarChart
                data={feedbackData.map(data => data).sort((data1: any, data2: any) => data2.total_cost - data1.total_cost)}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 15,
                }}
                barSize={30}
              >
                <XAxis type="category" dataKey="domains" tick={{ fontSize: chartFontSize }} tickFormatter={convertCamelCaseToTitleCase} />
                <YAxis type="number" tick={{ fontSize: chartFontSize }} />
                <Tooltip contentStyle={{ fontSize: chartFontSize }} />
                <Bar dataKey="total_cost" fill="#0063c3" name="Cost" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
      {isLoading ? (
        <></>
      ) : (
        <>
          <div className="feedback-detail-header-section">
            <h4>Feedback Details</h4>
            <div className="d-flex">
              <div className="ops-text ops-text-dark ops-fw-bold ops-fs-4 ">Filter Domain: </div>
              <Select
                className="select-filter-domain"
                placeholder="Select Domain"
                options={filterDomainOption}
                onChange={(e: any) => {
                  setFilterDomain(e);
                  setFiltertype('');
                }}
                value={filterDomain}
              />
            </div>
          </div>
          <FeebackDetailTable feedbackDetail={feedbackDetail} />
        </>
      )}
      </div>
      )}
    </div>
    
  );
};

export default SensingGptUserFeedback;
