import React, { useMemo } from 'react';
import { useState, useEffect, useContext } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../index.scss';
import { usageTrackingHeader } from './usageTrackingTable';
import { apiResponse, rowSpanning, filterMembers } from '../../../utility/commonMethods';
import { toast } from 'react-toastify';
import { Loader } from '@gitlab-rtsensing/component-library';
import { timeToHrMinSecond } from '../utility';
import { AppContext } from '../../../contexts/app-context';
import { MetricProps, RowSpanType } from '../usage-metric-interface';
import useRenderSpanning from '../../../hooks/use-render-spanning';

const AvgTimeSpent = (props: MetricProps) => {
  const columns = useMemo(() => usageTrackingHeader, []);
  const [usageTrackingData, setUsageTrackingData] = useState(Array<RowSpanType>);
  const [usageTrackingOriginal, setUsageTrackingOriginal] = useState<[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [filterVal, setFilterVal] = useState('');
  const [filterPageVal, setFilterPageVal] = useState('');
  const { userFilter } = useContext(AppContext);
  const [tableMsg, setTableMsg] = useState('No Data Available');
  const [isFilterDisabled, setFilterDisabled] = useState(false);
  const tableData = useRenderSpanning(usageTrackingData, columns, props.date, 'avg-time-spent', false, filterPageVal === '');

  useEffect(() => {
    if (props.clearData) {
      setFilterVal('');
      setFilterPageVal('');
      setUsageTrackingData([]);
      setUsageTrackingOriginal([]);
      getUsageTrackingData();
    }
  }, [props.clearData]);

  useEffect(() => {
    if (userFilter.filter) {
      filterGroupValue([], false, userFilter);
    } else {
      filterGroupValue(usageTrackingOriginal, false, { userId: '', fullname: '', filter: false });
    }
  }, [userFilter, props.includeCoreMember, props.userGroupValue]);

  useEffect(() => {
    if (usageTrackingOriginal.length === 0) return;
    setFilterVal('');
    setFilterPageVal('');
    filterGroupValue([], false, { userId: '', fullname: '', filter: false });
  }, [props.userGroupValue, props.includeCoreMember, usageTrackingOriginal]);

  const filterGroupValue = (dataArray: [], localSearch: Boolean, userFilter: { userId: string; fullname: string; filter: Boolean }) => {
    let filteredData = [];
    if (dataArray.length === 0 && !localSearch) {
      filteredData = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    } else {
      filteredData = filterMembers(dataArray, props.userGroupValue, props.includeCoreMember);
    }

    if (userFilter.userId !== '') {
      //In case of Bar Click
      filteredData = filteredData.filter((obj: { userid: string }) => obj.userid === userFilter.userId);
      if (filteredData.length === 0) {
        setTableMsg(`No Data Available for  ${userFilter.fullname}`);
      }
      setFilterDisabled(true);
    } else if (localSearch && dataArray.length === 0) {
      //Incase of local Search
      setTableMsg(`No Data Available`);
    } else if (filteredData.length === 0) {
      //Incase of GroupValue search
      setTableMsg(`No Data Available for  ${props.userGroupValue}`);
      setFilterDisabled(true);
    } else {
      //All Other case
      setFilterDisabled(false);
    }
    setUsageTrackingData(rowSpanning(filteredData));
  };

  const getUsageTrackingData = async () => {
    setLoading(true);

    const payload = {
      metric_id: '3',
      date_type: props.date.type,
      to_date: props.date.toDate,
      fr_date: props.date.fromDate,
    };
    try {
      const res = await apiResponse('post', 'get-usage-metric-data', [], payload);
      if (res?.data?.status === 'SUCCESS') {
        if (res?.data?.data?.user_metric) {
          if (res.data.data.user_metric.length === 0) {
            toast.error('Average Time Spent: Data not available!!');
          } else {
            //Below is for time formating
            res.data.data.user_metric = res.data.data.user_metric.map((obj: { average_timespent: string }) => {
              return Object.assign(obj, {
                average_timespent: timeToHrMinSecond(parseInt(obj.average_timespent)),
              });
            });

            setUsageTrackingOriginal(res.data.data.user_metric);
            filterGroupValue(res.data.data.user_metric, false, { userId: '', fullname: '', filter: false }); //Filter By User Group & SetUsageTrackingData
            props.setClearData(false);
          }
        }
      } else {
        console.log(res);
        toast.error('Average Time Spent: Failed to load!!');
        props.setClearData(false);
      }
    } catch (error) {
      console.log(error);
      toast.error('Average Time Spent: Something Went Wrong!!');
      props.setClearData(false);
    }
    setLoading(false);
  };

  const filterRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);
    let filterArr = dataArray.filter(
      (obj: { fullname: string; page_title: string }) =>
        obj.fullname.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1 && obj.page_title.toLowerCase().indexOf(filterPageVal.toLowerCase()) > -1,
    );
    if (evt.target.value === '') {
      filterGroupValue(dataArray, true, { userId: '', fullname: '', filter: false });
    } else {
      filterGroupValue(filterArr, true, { userId: '', fullname: '', filter: false });
    }
    setFilterVal(evt.target.value);
  };

  const filterPageRows = (evt: React.ChangeEvent<HTMLInputElement>) => {
    let dataArray = filterMembers(usageTrackingOriginal, props.userGroupValue, props.includeCoreMember);

    let filterArr = dataArray.filter(
      (obj: { page_title: string; fullname: string }) =>
        obj.page_title.toLowerCase().indexOf(evt.target.value.toLowerCase()) > -1 && obj.fullname.toLowerCase().indexOf(filterVal.toLowerCase()) > -1,
    );
    if (evt.target.value === '' && filterVal === '') {
      filterGroupValue(dataArray, true, { userId: '', fullname: '', filter: false });
    } else if (evt.target.value === '' && filterVal !== '') {
      filterGroupValue(filterArr, true, { userId: '', fullname: '', filter: false });
    } else {
      setUsageTrackingData(filterArr);
    }
    setFilterPageVal(evt.target.value);
  };

  return (
    <div className="usage-metric avg-time-spent">
      <div className="metric-header">
        <div>
          <label className="form-label">{props.date.displayValue}</label>
        </div>
        <div className="filters">
          <input
            type="text"
            name="filterName"
            className="metric-input metric-filter"
            value={filterVal}
            onChange={evt => {
              filterRows(evt);
            }}
            placeholder={'Filter Username'}
            disabled={isFilterDisabled}
          ></input>
          <input
            type="text"
            name="filterPage"
            className="metric-input metric-filter"
            value={filterPageVal}
            onChange={evt => {
              filterPageRows(evt);
            }}
            placeholder={'Filter Page'}
            disabled={isFilterDisabled}
          ></input>
        </div>
      </div>

      {isLoading ? (
        <div className="adm-loader-container">
          <Loader />
        </div>
      ) : (
        <div className="table-container usage-metric-container">
          {usageTrackingData.length === 0 ? (
            <p className="table-msg">{tableMsg}</p>
          ) : (
            <table className="usage-metric-table avg-time-spent">
              <thead>
                <tr>
                  {columns.map((data, indx: number) => {
                    return <th key={indx}>{data.Header}</th>;
                  })}
                </tr>
              </thead>
              <tbody>{tableData}</tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};
export default AvgTimeSpent;
