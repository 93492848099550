import { ColumnType, RowSpanType } from '../components/usage-metric-charts/usage-metric-interface';
import React, { ReactNode } from 'react';
import ExternalLinkLogo from '../assets/icons/external-link';
import { profileMatamo, workDayRedirect } from '../utility/commonMethods';
import Profile from '../assets/images/Profile.png';

export default function useRenderSpanning(
  trackingData: RowSpanType[],
  columns: ColumnType[],
  date: { matamoType: string; matamoDate: string },
  page: string,
  isUserIdDisable: Boolean,
  additionalCondition?: string | Boolean,
): ReactNode {
  var currentUserId = '';
  let columnChecks = ['fullname', 'userid'];
  let tableData = [];

  if (page === 'newvisitorlog') {
    columnChecks = ['fullname', 'userid', 'position', 'function'];
    additionalCondition = true; //Just bypass as true if we doesnt get any condition
  } else if (page === 'avg-time-spent') {
  } else {
    additionalCondition = true; //Just bypass as true if we doesnt get any condition
  }
  tableData = trackingData.map((data: any, indx: any) => {
    return (
      <tr key={indx}>
        {columns.map((colData: any, colIndx: any) => {
          if (columnChecks.indexOf(colData.accessor) > -1 && additionalCondition) {
            if (data.row_span_count > 1) {
              currentUserId = data.userid;
              if (colData.accessor === 'fullname') {
                return (
                  <td
                    className={'faux-link'}
                    rowSpan={data.row_span_count}
                    key={indx + colIndx}
                    onClick={() => profileMatamo(data['visitorid'], date.matamoType, date.matamoDate)}
                  >
                    <span className="user-name">{data[colData.accessor]}</span>
                    <span className="external-link">
                      <img src={Profile} alt="profile-link" className="profile" />
                    </span>
                  </td>
                );
              } else if (colData.accessor === 'userid') {
                return (
                  <td className="faux-link" key={indx + colIndx} rowSpan={data.row_span_count} onClick={() => workDayRedirect(data.profiledeeplink)}>
                    <span className="user-id" data-testid={data[colData.accessor]}>
                      {data[colData.accessor]}
                    </span>
                    <span className="external-link">
                      <ExternalLinkLogo />
                    </span>
                  </td>
                );
              } else {
                return (
                  <td key={indx + colIndx} rowSpan={data.row_span_count}>
                    {data[colData.accessor]}
                  </td>
                );
              }
            } else if (data.row_span_count === 1 && currentUserId === data.userid) {
              return <React.Fragment key={indx + colIndx}></React.Fragment>;
            } else if (colData.accessor === 'fullname') {
              return (
                <td
                  className={'faux-link'}
                  rowSpan={data.row_span_count}
                  key={indx + colIndx}
                  onClick={() => profileMatamo(data['visitorid'], date.matamoType, date.matamoDate)}
                >
                  <span className="user-name">{data[colData.accessor]}</span>
                  <span className="external-link">
                    <img src={Profile} alt="profile-link" className="profile" />
                  </span>
                </td>
              );
            } else if (colData.accessor === 'userid') {
              return (
                <td className="faux-link" key={indx + colIndx} onClick={() => workDayRedirect(data.profiledeeplink)}>
                  <span className="user-id" data-testid={data[colData.accessor]}>
                    {data[colData.accessor]}
                  </span>
                  <span className="external-link">
                    <ExternalLinkLogo />
                  </span>
                </td>
              );
            } else {
              return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
            }
          } else {
            return <td key={indx + colIndx}>{data[colData.accessor]}</td>;
          }
        })}
      </tr>
    );
  });

  return tableData;
}
